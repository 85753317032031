import { useToken } from '@chakra-ui/react';
import Head from 'next/head';
import { FC } from 'react';
import { brand, prodUrl } from '@/shared/constants';

interface IMetaDecoratorProps {
  title: string;
  description: string;
  keywords?: string;
  noIndex?: boolean;
  image?: string;
  twitterImage?: string;
}

const MetaDecorator: FC<IMetaDecoratorProps> = ({
  title,
  description,
  keywords,
  noIndex = false,
  image = '/logo/logo.svg',
  twitterImage,
}) => {
  const noIndexAttr = noIndex ? { content: 'none' } : { content: 'all' };
  const primaryPure = useToken('colors', 'primary.pure');

  return (
    <Head>
      <title>{title}</title>
      <meta
        key='mobile-web-app-capable'
        content='yes'
        name='mobile-web-app-capable'
      />
      <meta
        key='application-name'
        content={brand}
        name='application-name'
      />
      <meta
        {...noIndexAttr}
        name='robots'
      />
      <meta
        key='description'
        content={description.replace(/\n/g, ' ')}
        name='description'
      />
      <meta
        key='keywords'
        content={keywords}
        name='keywords'
      />
      <meta
        key='new_keywords'
        content={keywords}
        name='new_keywords'
      />
      <meta
        key='theme-color'
        content={primaryPure}
        name='theme-color'
      />
      <meta
        key='msapplication-TileColor'
        content='#ffffff'
        name='msapplication-TileColor'
      />
      <meta
        key='msapplication-TileImage'
        content='logo/ms-icon-144x144.png'
        name='msapplication-TileImage'
      />

      <meta
        key='og:title'
        content={title}
        property='og:title'
      />
      <meta
        key='og:site_name'
        content={brand}
        property='og:site_name'
      />
      <meta
        key='og:description'
        content={description.replace(/\n/g, ' ')}
        property='og:description'
      />
      <meta
        key='og:url'
        content={prodUrl}
        property='og:url'
      />
      <meta
        key='og:type'
        content='website'
        property='og:type'
      />
      <meta
        key='og:image'
        content={image}
        property='og:image'
      />
      <meta
        key='og:locale'
        content='en_US'
        property='og:locale'
      />
      <meta
        key='og:image:width'
        content='968'
        property='og:image:width'
      />
      <meta
        key='og:image:height'
        content='504'
        property='og:image:height'
      />

      <meta
        key='twitter:title'
        content={title}
        name='twitter:title'
      />
      <meta
        key='twitter:description'
        content={description.replace(/\n/g, ' ')}
        name='twitter:description'
      />
      <meta
        key='twitter:url'
        content={prodUrl}
        name='twitter:url'
      />
      <meta
        key='twitter:image:src'
        content={twitterImage || image}
        name='twitter:image:src'
      />
      <meta
        key='twitter:card'
        content='summary_large_image'
        name='twitter:card'
      />
      <meta
        key='twitter:domain'
        content={prodUrl}
        name='twitter:domain'
      />
    </Head>
  );
};

export default MetaDecorator;
