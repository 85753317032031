import { SOMETHING_WRONG_MSG } from '@/shared/constants';
import { useMutation, useToast } from '@/shared/helpers';
import { MutationResponse } from '@/shared/types';
import { CREATE_APP_LEAD } from './gql';

const useCreateAppLead = (onComplete?: () => void): MutationResponse => {
  const { showSuccessToast, showErrorToast } = useToast();

  return useMutation(CREATE_APP_LEAD, {
    onCompleted: () => {
      showSuccessToast('Your request for access has been sent');
      onComplete?.();
    },
    onError: () => {
      showErrorToast(SOMETHING_WRONG_MSG);
    },
  });
};

export default useCreateAppLead;
