import {
  Button,
  ButtonProps,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  MAXIMUM_CHARACTERS_MSG,
  MINIMUM_CHARACTERS_MSG,
  REQUIRED_FIELD_MSG,
} from '@/shared/constants';
import { EmailField, InputField, PhoneField, TextareaField } from '@/shared/ui';
import { useCreateAppLead } from '../model';

interface FormData {
  companyName: string;
  name: string;
  email: string;
  phone: string;
  description: string;
}

const validation = {
  minLength: {
    value: 2,
    message: MINIMUM_CHARACTERS_MSG(),
  },
  maxLength: {
    value: 20,
    message: MAXIMUM_CHARACTERS_MSG(),
  },
};

interface IRequestModalProps extends ButtonProps {
  buttonText?: string;
  description?: string;
}

const RequestModal: FC<IRequestModalProps> = ({
  buttonText = 'Request access',
  description = '',
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const methods = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      companyName: '',
      name: '',
      email: '',
      phone: '',
      description: '',
    } satisfies FormData,
  });

  const onCloseModal = () => {
    onClose();
    methods.reset();
  };

  const { action: createAppLead, isLoading } = useCreateAppLead(onCloseModal);

  const onSubmit = (formData: any) => {
    createAppLead({
      input: { ...formData, description: `${formData.description} ${description}`.trim() },
    });
  };

  return (
    <>
      <Button
        gridArea='button'
        variant={{ base: 'link', md: 'outline' }}
        onClick={onOpen}
        {...rest}
      >
        {buttonText}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent mx='sm'>
          <ModalCloseButton />
          <ModalBody py='lg'>
            <Heading
              size='lg'
              textAlign='center'
            >
              Access request
            </Heading>
            <FormProvider {...methods}>
              <form
                noValidate
                style={{ marginTop: '1rem' }}
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <InputField
                  isErrorSpace
                  isRequired
                  label='Your name'
                  name='name'
                  placeholder='Enter your name'
                  validationParameters={validation}
                />
                <EmailField
                  validationParameters={{
                    validate: (value: string, formData: FormData) => {
                      if (formData?.phone) {
                        return true;
                      }
                      return !!value || REQUIRED_FIELD_MSG('Email');
                    },
                  }}
                  onChange={() => methods.trigger('phone')}
                />
                <PhoneField
                  validationParameters={{
                    validate: (value: string, formData: FormData) => {
                      if (formData?.email) {
                        return true;
                      }
                      return !!value || REQUIRED_FIELD_MSG('Phone');
                    },
                  }}
                  onChange={() => methods.trigger('email')}
                />
                <InputField
                  isErrorSpace
                  isRequired
                  label='Company name'
                  name='companyName'
                  placeholder='Enter company name'
                  validationParameters={validation}
                />
                <TextareaField
                  isErrorSpace
                  label='Comments'
                  name='description'
                  placeholder='Any comments'
                />
                <Flex
                  gap='md'
                  justifyContent='space-between'
                  mt='md'
                >
                  <Button
                    isDisabled={isLoading}
                    variant='outline'
                    w='full'
                    onClick={onClose}
                  >
                    Close
                  </Button>
                  <Button
                    isLoading={isLoading}
                    type='submit'
                    w='full'
                  >
                    Send
                  </Button>
                </Flex>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestModal;
